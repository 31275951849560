import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { graphql } from 'gatsby';
import { Box, Flex } from 'rebass/styled-components';
import IndexPage from './index';
import { OutlineLink } from '../components/button';
import WarrantyIcon from '../images/warranty.inline.svg';
import FreeShippingIcon from '../images/free-shipping.inline.svg';
import CoinIcon from '../images/coin.inline.svg';
import adam from '../images/adam-celadin.jpg';
export const pageQuery = graphql`
  query IndexEnQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    file(relativePath: { eq: "three-knives-in-wood-3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1440) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    allMarkdownRemark(
      sort: {fields: frontmatter___position, order: ASC},
      filter: {
        frontmatter: { langKey: { eq: "en" }, type: { eq: "product" } }
      },
      limit: 3
    ) {
      edges {
        node {
          id
          html
          excerpt(pruneLength: 100)
          frontmatter {
            slug
            sku
            title
            currency
            price
            video
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 320) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`;
export const _frontmatter = {
  "type": "index",
  "langKey": "en"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = IndexPage;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Box mb={3} mdxType="Box">
  <h2>Sharp Blades by Adam Celadin</h2>
  <strong>5 times world champion</strong>, <strong>4 records in the Czech Book of Records</strong> and <strong>CEO of Sharp Blades</strong>.
    </Box>
    <img src={adam} alt="Adam Celadin" style={{
      maxWidth: '100%'
    }} loading="lazy" />
    <Box mb={5} mdxType="Box">
  <h2>We are the Sharp Blades</h2>
      <p>{`  We make `}<strong>{`hand crafted professional sport tools`}</strong>{` and `}<strong>{`melee weapons`}</strong>{` from our forge designed for `}<strong>{`close combat training`}</strong>{`, `}<strong>{`target practice/competition`}</strong>{`, and `}<strong>{`collectors of fine weaponry`}</strong>{`.`}</p>
      <p>{`  View our collection, or commission your own custom weapon built to your exact specifications.`}</p>
  <OutlineLink to="/about/" mdxType="OutlineLink">More about us</OutlineLink>
    </Box>
    <Box mb={5} mdxType="Box">
  <h2>Precision. Quality. Originality.</h2>
      <p>{`  Each of our original designs is hand crafted using time honored techniques.`}</p>
      <p>{`  World Champion Knife Thrower Adam Čeladín and Master Blacksmith Eduard Vyoral bring years of combined passion, knowledge, and experience together to create weapons that are not only unique and attractive, but also practical and durable.`}</p>
  <OutlineLink to="/production/" mdxType="OutlineLink">See our manufacturing process</OutlineLink>
    </Box>
    {
      /* Icons by https://www.iconfinder.com/iconsets/ecommerce-26 */
    }
    <Flex flexWrap="wrap" flexDirection={('column', 'row')} justifyContent={['flex-start', 'space-between']} textAlign="center" mdxType="Flex">
  <Flex flexDirection="column" alignItems="center" width={['100%', '30%']} mdxType="Flex">
    <WarrantyIcon mdxType="WarrantyIcon" />
    <Text as="p" mdxType="Text">Lifetime warranty</Text>
  </Flex>
  <Flex flexDirection="column" alignItems="center" width={['100%', '30%']} mdxType="Flex">
    <FreeShippingIcon mdxType="FreeShippingIcon" />
    <Text as="p" mdxType="Text">Free shipping for orders above $250</Text>
  </Flex>
  <Flex flexDirection="column" alignItems="center" width={['100%', '30%']} mdxType="Flex">
    <CoinIcon mdxType="CoinIcon" />
    <Text as="p" mdxType="Text">10% discount for returning customers</Text>
  </Flex>
    </Flex>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      